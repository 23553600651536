import React, { useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// styles
import useStyles from "./styles";

// Components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';

import {firestore, storage} from '../../services/firebase'

export default function EditPage() {
  var classes = useStyles();
  const eventImageInput = useRef();

  var [eventImage, setEventImage] = useState(null);
  var [eventID, setEventID] = useState("");
  var [eventHeading, setEventHeading] = useState("");
  var [eventTitle, setEventTitle] = useState("");
  var [eventDesc, setEventDesc] = useState("");
  var [author, setAuthor] = useState("");
  var [eventDate, setEventDate] = useState("");

  var [editEventID, setEditEventID] = useState("");
  var [editEventHeading, setEditEventHeading] = useState("");
  var [editEventTitle, setEditEventTitle] = useState("");
  var [editEventDesc, setEditEventDesc] = useState("");
  var [editAuthor, setEditAuthor] = useState("");
  var [editEventDate, setEditEventDate] = useState("");

  const handleEventSubmit = async () => {
    storage.ref(`Orchids-EventImages/${eventImage}`).put(eventImage).then((snapshot) => {
      snapshot.ref.getDownloadURL().then(async(url) => {
        await firestore.collection("Orchids-Events").add({
          eventID: eventID,
          eventHeading: eventHeading,
          eventTitle: eventTitle,
          eventDesc: eventDesc,
          author: author,
          eventDate: eventDate,
          eventImage: url
        }).then(() => {
          setEventID("");
          setEventHeading("");
          setEventTitle("");
          setEventDesc("");
          setAuthor("");
          setEventDate("");
          eventImageInput.current = "";
          toast.success('Event Added Successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }).catch((err) => {
          toast.error('Please Try Again', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log(err.message);
        })
      })
    })
  }

  const handleEventImageChange = async(e) => {
    if(e.target.files[0]){
      const image = e.target.files[0];
      setEventImage(image)
    }
  }

  const handleEventEdit = () => {
    console.log('Bhosadappa')
    firestore.collection("Orchids-Events").where("eventID", "==", editEventID).onSnapshot(async (snapshot) => {
      firestore.collection("Orchids-Events").doc(snapshot.docs[0].id).update({
        eventID: editEventID,
        eventHeading: editEventHeading,
        eventTitle: editEventTitle,
        eventDesc: editEventDesc,
        author: editAuthor,
        eventDate: editEventDate,
      }).then(() => {
        setEditEventID("");
        setEditEventHeading("");
        setEditEventTitle("");
        setEditEventDesc("");
        setEditAuthor("");
        setEditEventDate("");
        toast.success('Event Edited Successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }).catch((err) => {
        toast.error('Please Try Again', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err.message);
      })
    })
  }

  return (
    <>
      <PageTitle title="Edit Events" />

      {/* Add Events */}

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Widget title="" disableWidgetMenu>
            <Typography
              variant="h3"
              color="success"
              className={classes.text}
            >
              Add Events
            </Typography>
            <div className={classes.dashedBorder}>
              <TextField
                id="outlined-full-width"
                label="Event ID"
                color="success"
                value={eventID}
                onChange={(e) => { setEventID(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Event ID"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="outlined-full-width"
                label="Event Heading"
                value={eventHeading}
                onChange={(e) => { setEventHeading(e.target.value) }}
                color="success"
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Heading"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />

              <TextField
                id="outlined-full-width"
                label="Event Title"
                color="success"
                value={eventTitle}
                onChange={(e) => { setEventTitle(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Title"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="outlined-full-width"
                label="Event Description"
                color="success"
                value={eventDesc}
                onChange={(e) => { setEventDesc(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Description"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="outlined-full-width"
                label="Author"
                color="success"
                value={author}
                onChange={(e) => { setAuthor(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Author"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="outlined-full-width"
                label="Date"
                color="success"
                value={eventDate}
                onChange={(e) => { setEventDate(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Date"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <input type='file' onChange={handleEventImageChange} ref={eventImageInput}></input>
              <br />
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleEventSubmit}
                style={{ marginRight: 10, margin: 10 }}
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                className={classes.button}
                // startIcon={<DeleteIcon />}
              >
                Cancel
              </Button>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </Widget>
        </Grid>

        {/* Edit Events */}

        <Grid item xs={12} md={6}>
          <Widget title="" disableWidgetMenu>
            <Typography
              variant="h3"
              color="success"
              className={classes.text}
            >
              Edit Events
            </Typography>
            <div className={classes.dashedBorder}>
              <TextField
                id="outlined-full-width"
                label="Event ID"
                color="success"
                value={editEventID}
                onChange={(e) => { setEditEventID(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Enter Event Number"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="outlined-full-width"
                label="Edit Event Heading"
                value={editEventHeading}
                onChange={(e) => { setEditEventHeading(e.target.value) }}
                color="success"
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Event Heading"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />

              <TextField
                id="outlined-full-width"
                label="Event Title"
                color="success"
                value={editEventTitle}
                onChange={(e) => { setEditEventTitle(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Event Title"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="outlined-full-width"
                label="Edit Event Description"
                color="success"
                value={editEventDesc}
                onChange={(e) => { setEditEventDesc(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Event Description"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="outlined-full-width"
                label="Edit Author"
                color="success"
                value={editAuthor}
                onChange={(e) => { setEditAuthor(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Event Author"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="outlined-full-width"
                label="Edit Date"
                color="success"
                value={editEventDate}
                onChange={(e) => { setEditEventDate(e.target.value) }}
                style={{ margin: 10, paddingRight: "25%" }}
                placeholder="Event Date"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleEventEdit}
                style={{ marginRight: 10, margin: 10 }}
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                className={classes.button}
              >
                Cancel
             </Button>
            </div>
          </Widget>
        </Grid>

      </Grid>
    </>
  );
}
