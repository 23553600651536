import React, { useEffect, useState } from "react";
import { firestore } from '../../services/firebase';
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";

export default function ContactUsForm() {

    const [tableData, setTableData] = useState([])

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            await firestore.collection("Orchids-ContactUs-Form").onSnapshot(async (snapshot) => {
                if (isMounted) {
                    setTableData([])
                }
                snapshot.forEach((snap) => {
                    if (snap.exists) {
                        if (isMounted) {
                            let tempData = snap.data();
                            let finalTemp = [tempData.contactName, tempData.contactEmail, tempData.contactPhone, tempData.contactMessage, tempData.contactDate, tempData.contactTime];
                            setTableData(prevState => [...prevState, finalTemp])
                        }
                    }
                })
            })
        }
        fetchData();

        return () => {
            isMounted = false;
        }
    }, [])

    return (
        <>
            <PageTitle title="Contact Us Data" />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <MUIDataTable
                        title="Contact Us"
                        data={tableData}
                        columns={["First Name", "Email", "Phone", "Message", "Date", "Time"]}
                        options={{
                            filterType: "checkbox",
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}