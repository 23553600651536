import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyCdZ1-zvSxnQ5pVW9R3XzePRayugvpyQuM",
    authDomain: "jit-admin.firebaseapp.com",
    projectId: "jit-admin",
    storageBucket: "jit-admin.appspot.com",
    messagingSenderId: "693676856322",
    appId: "1:693676856322:web:1819aa8b3b589f8073031b",
    measurementId: "G-VCBDECLZJ1"
};

firebase.initializeApp(firebaseConfig);


export const auth = firebase.auth;

export const firestore = firebase.firestore();

export const storage = firebase.storage();
