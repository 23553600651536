import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, Link} from '@material-ui/core';

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import EditPage from "../../pages/typography/EventsPage";
import CareersPage from "../../pages/typography/CareersPage";
import ContactUsForm from "../../pages/tables/ContactUsForm";
import FooterSubscribe from './../../pages/tables/FooterSubscribe';

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" render={() => <Redirect to="/app/edit/events" />} />
              <Route path="/app/edit/events" component={EditPage} />
              <Route path="/app/edit/careers" component={CareersPage} />
              <Route path="/app/view/contactus" component={ContactUsForm} />
              <Route path="/app/view/subscribers" component={FooterSubscribe} />
              <Route path="/app/items" render={() => <Redirect to="/app/edit/events" />} />
              <Route path="/app/edit" render={() => <Redirect to="/app/edit/events" />} />
              <Route path="/app/orders" render={() => <Redirect to="/app/edit/events" />} />
              <Route path="/app/notifications" render={() => <Redirect to="/app/edit/events" />} />  
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/edit/events" />}
              />
              <Route
                exact
                path="/app/edit/events/*"
                render={() => <Redirect to="/app/edit/events" />}
              />
              <Route path="/app/ui/maps" render={() => <Redirect to="/app/edit/events" />} />
              <Route path="/app/ui/icons" render={() => <Redirect to="/app/edit/events" />} />
              <Route path="/app/ui/charts" render={() => <Redirect to="/app/edit/events" />} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                href={'https://onsipl.com'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Orchids Network
                </Link>
              </div>
              
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
